import type { ReactNode } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { Snackbar, Alert } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';

import type { AlertColor } from '@mui/material';
interface ToastContextProps {
  success: (message: string) => void;
  warning: (message: string) => void;
  error: (message: string) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

interface ToastState {
  open: boolean;
  message: string;
  severity: AlertColor;
}

const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [toast, setToast] = useState<ToastState>({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleClose = () => {
    setToast({ ...toast, open: false });
  };

  const showToast = (message: string, severity: AlertColor) => {
    setToast({ open: true, message, severity });
  };

  const contextValue: ToastContextProps = {
    success: (message) => showToast(message, 'success'),
    warning: (message) => showToast(message, 'warning'),
    error: (message) => showToast(message, 'error')
  };

  const backgroundColor =
    {
      success: 'rgb(237, 247, 237)',
      warning: '#FFE4D6',
      error: 'rgb(253, 237, 237)',
      info: 'rgb(232, 244, 253)'
    }[toast.severity] || 'inherit';

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <Snackbar
        open={toast.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert
          onClose={handleClose}
          severity={toast.severity}
          icon={getIcon(toast.severity)}
          sx={{ backgroundColor }}>
          {toast.message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};

const WarningIcon = () => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.843 1.802C6.872 0.601 7.886 0 9 0C10.114 0 11.128 0.6 13.157 1.802L13.843 2.208C15.872 3.41 16.886 4.011 17.443 5C18 5.99 18 7.19 18 9.594V10.406C18 12.809 18 14.011 17.443 15C16.886 15.99 15.872 16.59 13.843 17.791L13.157 18.198C11.128 19.399 10.114 20 9 20C7.886 20 6.872 19.4 4.843 18.198L4.157 17.791C2.128 16.591 1.114 15.989 0.557 15C-5.96046e-08 14.01 0 12.81 0 10.406V9.594C0 7.19 -5.96046e-08 5.989 0.557 5C1.114 4.01 2.128 3.41 4.157 2.208L4.843 1.802ZM10 14C10 14.2652 9.89464 14.5196 9.70711 14.7071C9.51957 14.8946 9.26522 15 9 15C8.73478 15 8.48043 14.8946 8.29289 14.7071C8.10536 14.5196 8 14.2652 8 14C8 13.7348 8.10536 13.4804 8.29289 13.2929C8.48043 13.1054 8.73478 13 9 13C9.26522 13 9.51957 13.1054 9.70711 13.2929C9.89464 13.4804 10 13.7348 10 14ZM9 4.25C9.19891 4.25 9.38968 4.32902 9.53033 4.46967C9.67098 4.61032 9.75 4.80109 9.75 5V11C9.75 11.1989 9.67098 11.3897 9.53033 11.5303C9.38968 11.671 9.19891 11.75 9 11.75C8.80109 11.75 8.61032 11.671 8.46967 11.5303C8.32902 11.3897 8.25 11.1989 8.25 11V5C8.25 4.80109 8.32902 4.61032 8.46967 4.46967C8.61032 4.32902 8.80109 4.25 9 4.25Z"
      fill="#FF3632"
    />
  </svg>
);

const getIcon = (severity: AlertColor) => {
  switch (severity) {
    case 'success':
      return <CheckIcon fontSize="inherit" />;
    case 'warning':
      return <WarningIcon />;
    case 'error':
      return <ErrorIcon fontSize="inherit" />;
    default:
      return null;
  }
};

const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export { ToastProvider, useToast };
