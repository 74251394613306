import React, {
  createContext,
  useState,
  useEffect,
  type Dispatch,
  type ReactNode,
  type SetStateAction
} from 'react';

import { getCookie } from '@/utils/cookies';
// 定义用户类型
interface User {
  token: string | null;
  email: string | null;
  mobile: string | null;
  firstName: string | null;
  lastName: string | null;
}

// 定义 Context 的类型
interface UserContextType {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
  openLoginDialog: boolean;
  setOpenLoginDialog: Dispatch<SetStateAction<boolean>>;
}

// 创建 Context
const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => {
    console.warn('setUser method is not implemented');
  },
  openLoginDialog: false,
  setOpenLoginDialog: () => {
    console.warn('setOpenLoginDialog method is not implemented');
  }
});

// Provider 组件
interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [openLoginDialog, setOpenLoginDialog] = useState<boolean>(false);

  // 初始化时从 Cookies 中读取用户信息
  useEffect(() => {
    const token = getCookie('token');
    const email = getCookie('email');
    const mobile = getCookie('mobile');
    const firstName = getCookie('firstName');
    const lastName = getCookie('lastName');

    setUser({ token, email, mobile, firstName, lastName });
  }, []);

  // 设置登录对话框的显示状态
  const setOpenLoginDialogHandler: UserContextType['setOpenLoginDialog'] = (open) => {
    setOpenLoginDialog(open);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        openLoginDialog,
        setOpenLoginDialog: setOpenLoginDialogHandler
      }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
