import type { ReactElement, ReactNode } from 'react';

import { CacheProvider } from '@emotion/react';
import { CircularProgress } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import AOS from 'aos';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { SWRConfig } from 'swr';

import type { EmotionCache } from '@emotion/react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';

import createEmotionCache from '@/clients/EmotionCache';
import English from '@/content/locales/en_US.json';
import Chinese from '@/content/locales/zh_CN.json';
import { UserProvider } from '@/hooks/useUser/context';
import theme from '@/theme';
import '@/styles/global.css';
import getShortLocale from '@/utils/getShortLocale';
import 'aos/dist/aos.css';
import { ToastProvider } from '@/utils/toast';
import { isBrowser } from '@/utils/utils';

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  emotionCache: EmotionCache;
};

const clientSideEmotionCache = createEmotionCache();

function MyApp(props: AppPropsWithLayout) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const { locale } = useRouter();
  const shortLocale = getShortLocale(locale);
  const getLayout = Component.getLayout ?? ((page) => page);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isBrowser()) return;
    AOS.init({
      disable: window.innerWidth < 769,
      offset: 0,
      duration: 600,
      once: true
    });
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  const messages = useMemo(() => {
    switch (shortLocale) {
      case 'zh':
        return Chinese;
      case 'en':
        return English;
      default:
        return English;
    }
  }, [shortLocale]);

  const router = useRouter();

  useEffect(() => {
    const handleStart = (url: string) => {
      setLoading(true);
    };

    const handleComplete = (url: string) => {
      setLoading(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router]);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta
          name="viewport"
          content="initial-scale=1, width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link
          rel="shortcut icon"
          href="https://www.stey.com/static/img/icon.png"
          type="image/x-icon"
        />
      </Head>
      <Script src="https://static.geetest.com/v4/gt4.js" />
      <IntlProvider locale={shortLocale} messages={messages}>
        <SWRConfig
          value={{
            fetcher: ([url, locale]) =>
              fetch(url, {
                headers: {
                  'Accept-Language': (locale || shortLocale) === 'zh' ? 'zh-CN' : 'en-US'
                }
              })
                .then((res) => res.json())
                .then((data) => {
                  console.log(`@@@ swr featch ${url} success --- ${data.data}`);
                  return data.data;
                }),
            revalidateOnFocus: false
          }}>
          <ThemeProvider theme={theme}>
            <UserProvider>
              <ToastProvider>
                <CssBaseline />
                {loading && (
                  <div
                    style={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                      zIndex: 9999
                    }}>
                    <CircularProgress />
                  </div>
                )}
                {getLayout(<Component {...pageProps} />)}
              </ToastProvider>
            </UserProvider>
          </ThemeProvider>
        </SWRConfig>
      </IntlProvider>
    </CacheProvider>
  );
}

export default Sentry.withProfiler(MyApp);
