var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"LtmNvZ-GvMuWp-jD1Utpr"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const currentEnv = () => {
  switch (process.env.TARGET) {
    case 'dev':
      return 'dev';
    case 'uat':
      return 'uat';
    case 'prod':
      return 'prod';
    default:
      return 'local';
  }
};

const isProduction = currentEnv() === 'prod';

Sentry.init({
  enabled: process.env.TARGET,
  environment: currentEnv(),
  dsn: SENTRY_DSN || 'https://f4fc0560a6d832c9a6560de7ed666a9d@sentry.stey.com/43',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: isProduction ? 1.0 : 0.01
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
