export function isBrowser() {
  return !!(typeof window !== 'undefined' && window.document && window.document.createElement);
}

export function getTimestamp(isoDate: string): number {
  const date = new Date(isoDate);
  return date.getTime();
}

export function getCurrentBrowserLanguage() {
  // 根据目前浏览器语言选择返回 en / zh
  const browserLanguage = navigator.language;
  return browserLanguage.startsWith('zh') ? 'zh' : 'en';
}
