// Helper function to set a cookie
export const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
};

// Helper function to get a cookie
export const getCookie = (name: string): string =>
  document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');

// Helper function to remove a cookie
export const removeCookie = (name: string) => {
  setCookie(name, '', -1);
};
